




























































































































import { Component, Vue } from 'vue-property-decorator';
import RetirementFundDefinePlanViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/retirement-fund/retirement-fund-define-plan-view-model';

@Component({
  name: 'RetirementFundDefinePlan',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RetirementFundDefinePlan extends Vue {
  view_model = Vue.observable(
    new RetirementFundDefinePlanViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
